.scrollbar::-webkit-scrollbar {
    width: 6px;
    height: 15px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #D9D9D9;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: linear-gradient(360deg, #21D6CC 0.99%, #00218C 58.48%);
    border-radius: 100vh;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #c0a0b9;
  }
.impactOne {
  position:relative;
  display : flex;
  width: 150px;
  height: 150px;
  background: rgba(0, 32, 140, 0.15);
  border-radius: 50% 40% 40% 20% / 70% 50% 30% 25%;
}
.impactTwo {
  position:relative;
  display : flex;
  width: 150px;
  height: 150px;
  background: rgba(0, 32, 140, 0.15);
  border-radius: 30% 50% 30% 45% ;
}
.impactThree {
  position:relative;
  display : flex;
  width: 150px;
  height: 150px;
  background: rgba(0, 32, 140, 0.15);
  border-radius:  70% 50% 30% 25%;
}
.impactFour {
  position:relative;
  display : flex;
  width: 150px;
  height: 150px;
  background: rgba(0, 32, 140, 0.15);
  border-radius: 30% 67% 38% 47%;
}

@media only screen and (min-width: 768px) {
  height: 88px;
}
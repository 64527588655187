.blurDiv {
    filter: blur(3px);
  -webkit-filter: blur(3px);
}

.premiumCard {
  position: absolute; 
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  margin-top : auto;
  top: 0;
  bottom : 0;
  margin-bottom : auto;
  width : 300px;
  height : 300px;
  background-color: #f4f4fc;
  border : 1px solid gray;
  border-radius : 10px;
  display : flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding : 20px
}

.premiumButton {
  display: flex;
  padding : 10px;
  justify-content: center;
  align-items: center;
  background : blue;
  border-radius : 10px;
  border : 1px solid gray;
  margin-top : 30px;
  cursor : pointer;
  color : white;
}